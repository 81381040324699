import React from "react";

const approachContent = [
  {
    title: "Initial Consult",
    descriptions: `We'll arrange a meeting with you to discuss the specifications and requirements for your website, and get a feel for the style of your brand.`,
    delayAnimation: "0",
  },
  {
    title: "Contract & Downpayment",
    descriptions: `We'll draw up a contract and ask for an initial payment.`,
    delayAnimation: "100",
  },
  {
    title: "Design Presentation",
    descriptions: `We'll present to you a selection of web design mockups that we think would mesh well with your branding, which allows us to get a strong idea of the design vision you have for the website.`,
    delayAnimation: "100",
  },
  {
    title: "Development",
    descriptions: `We dive right in and get to developing your site, providing you with regular updates. This process usually takes between 3 to 6 weeks, depending on the complexity of the design.`,
    delayAnimation: "100",
  },
  {
    title: "Finalization, Touch-ups & Going Live",
    descriptions: `Before going live, we'll give you ample time to review and request any changes. Once we're all happy, the final payment instalment is requested and we launch the site.  `,
    delayAnimation: "100",
  },
];

const Approach2 = () => {
  return (
    <ul className="ptf-process-steps">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default Approach2;
