import React from "react";

const approachContent = [
  {
    title: "Website Design & Development",
    descriptions: `From Business sites, to Ecommerce sites, to Personal sites, we proudly develop all types and styles of websites to meet your specification.`,
    delayAnimation: "0",
  },
  {
    title: "Website Hosting & Maintenance",
    descriptions: `We're happy to handle the hosting & maintenance of your site, with 99.9% uptime`,
    delayAnimation: "60",
  },
  {
    title: "Search Engine Optimization",
    descriptions: `We'll optimize your website with the intention to lower loading times & rank highly on Google searches.`,
    delayAnimation: "120",
  },
  {
    title: "Mobile App Development",
    descriptions: `We code both apple (iOS) & Android apps. As well as cross platform solutions.`,
    delayAnimation: "180",
  },
  {
    title: "Internal software & Databases",
    descriptions: `Are you looking to automate business processes or better manage data? We're happy to help.`,
    delayAnimation: "200",
  },
];

const Approach3 = () => {
  return (
    <ul className="ptf-process-steps ">
      {approachContent.map((val, i) => (
        <li
          className="ptf-process-item no-nums"
          data-aos="fade"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <h6 className="ptf-process-item__title">{val.title}</h6>
          <p className="ptf-process-item__text">{val.descriptions}</p>
        </li>
      ))}
    </ul>
  );
};

export default Approach3;
