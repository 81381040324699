import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import ServiceListThree from "../../../components/list/ServiceListThree";
import Faq from "../../../components/faq/Faq";
import Skills from "../../../components/skill/Skills";
import Approach2 from "../../../components/service/Approach2";
import CounterThree from "../../../components/counter/CounterThree";
import ImageGridFour from "../../../components/image-grid/ImageGridFour";
import HeaderAgency from "../../../components/header/HeaderAgency";
import Approach3 from "../../../components/service/Approach3";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import { Link } from "react-router-dom";

const ServiceDetails = () => {
  return (
    <div>
      <Helmet>
        <title>GMK Digital - Our Services</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderAgency />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">

          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "2.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
            <div className="container">
              <div className="row">
                <div className="col-xl-8">
                  <section>
                    {/* <!--Spacer--> */}

                    <div className="container">
                      <div className="row">
                        <div className="col-xl-9">
                          {/* <!--Animated Block--> */}
                          <div
                            className="ptf-animated-block"
                            data-aos="fade"
                            data-aos-delay="0"
                          >
                            <h1 className="large-heading bolder-gab">Our Services</h1>
                            <div
                              className="ptf-spacer"
                              style={{ "--ptf-xxl": "2.5rem" }}
                            ></div>
                            <p className="fz-18">
                              We provide multiple services to fully manage your business' web presence.
                              From web design, development, and integrations, to full hosting and maintenance, and internal tooling, we handle it all.
                              <br></br><br></br>

                            </p>
                          </div>
                          {/* <!--Spacer--> */}

                          <div
                            className="ptf-animated-block"
                            data-aos="fade"
                            data-aos-delay="0"
                          >

                            {/* <!--Spacer--> */}
                            <div
                              className="ptf-spacer"
                              style={{ "--ptf-xxl": "1.875rem" }}
                            ></div>

                          </div>
                          {/* <!--Spacer--> */}

                          <div
                            className="ptf-spacer"
                            style={{ "--ptf-xxl": "2.25rem", "--ptf-md": "3.125rem" }}
                          ></div>
                        </div>
                        <p className="fz-18">
                          GMK Digital prioritizes rapid development, so that we can build and launch your product as fast as possible, while maintaining exceptional quality.
                        </p>
                        <div className="col-lg-12">
                          {/* <!--Animated Block--> */}
                          {/* <ImageGridFour /> */}
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >

                  </div>




                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "8.375rem" }}
                  ></div>
                  <Approach3 />
                  {/* <!--Animated Block--> */}

                  {/* <!--Spacer--> */}


                  {/* <!--Spacer--> */}

                  {/* <!--Animated Block--> */}

                  {/* <!--Spacer--> */}

                  {/* <!--Spacer--> */}

                  {/* <!--Process Steps--> */}

                </div>

                <div className="col-xl-4">
                  <div className="ptf-sidebar ptf-sidebar--right">
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-services-list">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        {/* <h4 className="ptf-widget-title">Services List</h4> */}
                        {/* <ServiceListThree /> */}
                      </div>
                    </div>
                    {/* <!--Widget--> */}
                    <div className="ptf-widget ptf-widget-faq">
                      {/* <!--Animated Block--> */}
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="0"
                      >
                        <h4 className="ptf-widget-title">FAQs</h4>
                        <Faq />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "8.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
      <div className="container text-center">
        <div
          className="
                        fz-130
                        fz-90--md
                        has-black-color has-secondary-font
                        fw-normal
                        lh-1p1
                      "
        >
          <Link className="ptf-filled-link" to="/contact">
            Get a Quote
            <div className="fz-80 has-black-color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                style={{ height: "1em" }}
                viewBox="0 0 17 17"
              >
                <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
              </svg>
            </div>
          </Link>

        </div>
      </div>
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "5.375rem" }}
      ></div>
      {/* End ptf-main */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>

    </div>
  );
};

export default ServiceDetails;
