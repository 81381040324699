import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../../components/header/HeaderDefault";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import ServiceListThree from "../../../components/list/ServiceListThree";
import Faq from "../../../components/faq/Faq";
import Skills from "../../../components/skill/Skills";
import Approach2 from "../../../components/service/Approach2";
import CounterThree from "../../../components/counter/CounterThree";
import ImageGridFour from "../../../components/image-grid/ImageGridFour";
import HeaderAgency from "../../../components/header/HeaderAgency";
import Approach3 from "../../../components/service/Approach3";
import CopyRightThree from "../../../components/footer/copyright/CopyRightThree";
import FooterThree from "../../../components/footer/FooterThree";
import { Link } from "react-router-dom";

const Thanks = () => {
  return (
    <div>
      <Helmet>
        <title>GMK Digital - Thank you</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderAgency />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--service-detail">

          {/* Top Portion */}

          <section>
            {/* <!--Spacer--> */}

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "2.625rem", "--ptf-md": "2.8125rem" }}
            ></div>
               <div className="container text-centers">
              <h1 className="fz-130 custom-header-text fz-90--md has-secondary-font fw-normal">
                Thanks! <br></br> <span className="has-accent-1"> We'll be in touch </span>



              </h1>
            </div>
            {/* <!--Spacer--> */}
          
          </section>
          {/* End .main content with sidebar */}
        </div>
        {/* End .ptf-page */}
      </div>
    

      {/* End ptf-main */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>

    </div>
  );
};

export default Thanks;
