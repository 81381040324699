import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "How much does a website cost?",
    desc: `Prices vary drastically depending on the complexity and scope of the website. Prices also vary based on complexity of design.
    Our general pricing rule is we charge around $5000 NZD for a 1-3 page website.`,
    expand: "a",
  },
  {
    title: "How long do I get support coverage?",
    desc: `We will provide full support for common issues and allow for up to 3 simple edits per month, free of charge for 6 months. After that we will continue to be flly available for support but any edits will incur an hourly fee. `,
    expand: "b",
  },
];

const Faq = () => {
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd>{item.desc}</dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq;
