import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderTrending from "../../components/header/HeaderTrending";
import FooterThree from "../../components/footer/FooterThree";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";
import PortfolioThree from "../../components/portfolio/PortfolioThree";
import ServiceTwo from "../../components/service/ServiceTwo";
import BrandTwo from "../../components/brand/BrandTwo";
import HeaderFreelancer from "../../components/header/HeaderFreelancer";
import HeaderAgency from "../../components/header/HeaderAgency";

const HomeTrending = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-trending ">
      <Helmet>
        <title>GMK Digital - Home </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderAgency />
      {/* End  HeaderTrending */}

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-trending">
            {/*=============================================
            Start Hero Section 
            ============================================== */}
            <section>
              {/* <!--Spacer--> */}

              <div id="hero-main">
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "2.75rem", "--ptf-md": "1.375rem" }}
                ></div>

                <div className="container">
                  <div className="col-lg-38">
                    {/* {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="fz-130 custom-header-text fz-90--md has-secondary-font fw-normal">
                       Modern  <span className="has-accent-1"> websites </span>

                        & <span className="has-accent-1"> software </span>



                        for NZ businesses


                      </h1>
                    </div>
                  </div>
                  <div className="col-lg-5">
                  </div>
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.625rem", "--ptf-md": "2.8125rem" }}
                  ></div>
                </div>
              </div>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
              ></div>
              <div className="container">

                {/* <!--Spacer--> */}


                <div className="row">
                  <div className="col-lg-5">
                    {/* <!--Phone Block--> */}
                    <div className="ptf-phone-block">
                      {/* <div className="ptf-phone-block__icon">
                        <i className="lnil lnil-phone"></i>
                      </div> */}
                      {/* <div className="ptf-phone-block__caption">
                        8am - 5pm
                        <br />
                        Mon - Fri
                      </div> */}
                      <div className="ptf-phone-block__phone">
                        <a href="tel:+64226781085"><span className="phone-prefix">(+64)</span> 022 678 1085</a>

                      </div>


                    </div>
                    <div className="ptf-phone-block">

                      {/* <div className="ptf-phone-block__caption">
                        EMAIL

                      </div> */}
                      <div className="ptf-phone-block__email">
                        <a className="has-accent-2 bolder-gab" href="mailto:hello@gmkdigital.nz">hello@gmkdigital.nz</a>

                      </div>


                    </div>
                    {/* <!--Spacer--> */}

                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": " 5rem" }}
                    ></div>
                    {/* {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="200"
                    >


                    </div>
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": " 8rem" }}
                      ></div>

                    </div>
                  </div>

                  <div className="col-lg-7">
                    {/* {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <p className="fz-30" style={{ paddingRight: "1rem" }}>
                        We have over 10 years experience developing fast, modern websites for businesses all around New Zealand. Get a <Link to="/contact" className="has-accent-1 bolder-gab underline-gab"> Quote</Link> today.
                      </p>

                    </div>

                  </div>
                </div>
                {/* End .row */}
              </div>

              <div
                className="ptf-animated-block"
                data-aos="fade"
                data-aos-delay="100"
              ><div className="container">
                  <h3 className="secondary-title fz-30 fz-90--md has-secondary-font fw-normal">We build fast.</h3>
                  <p className="fz-30" style={{ paddingRight: "1rem" }}>
                    Many digital agencies quote 6+ months,
                    as well as long turn around times to build your website.
                    GMK digital can provide you a fully tailored and bespoke digital package within 1 - 3 months of first consultation.
                    <br></br> <br></br>
                  </p></div>
              </div>

              <div
                className="ptf-animated-block"
                data-aos="fade"
                data-aos-delay="100"
              ><div className="container">
                  <h3 className="secondary-title fz-30 fz-90--md has-secondary-font fw-normal">Zero setup.</h3>
                  <p className="fz-30" style={{ paddingRight: "1rem" }}>
                    Once we build your site, we offer to take care of hosting, your search engine visibility, and everything else related to your website.
                    <br></br> <br></br>
                  </p></div>
              </div>

              <div
                className="ptf-animated-block"
                data-aos="fade"
                data-aos-delay="100"
              ><div className="container">
                  <h3 className="secondary-title fz-30 fz-90--md has-secondary-font fw-normal">Fully transparent. </h3>
                  <p className="fz-30" style={{ paddingRight: "1rem" }}>
                    Once completed, we will provide you with the full source code of your website so that you're in control.
                    <br></br> <br></br>
                  </p></div>
              </div>




              {/* End .container */}
              <div className="container text-center">
                <div className="d-inline-flex text-start">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "5.25rem" }}
                    ></div>
                    <div className="fz-30">What are you waiting for?</div>
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "5.25rem" }}
                    ></div>
                    <div
                      className="
                        fz-130
                        fz-90--md
                        has-black-color has-secondary-font
                        fw-normal
                        lh-1p1
                      "
                    >
                      <Link className="ptf-filled-link" to="/contact">
                        Get a Quote
                        <div className="fz-80 has-black-color">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            style={{ height: "1em" }}
                            viewBox="0 0 17 17"
                          >
                            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                          </svg>
                        </div>
                      </Link>
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "5.25rem" }}
                      ></div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div id="portfolio"
                className="ptf-spacer"
                style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
              ></div>

            </section>

            {/*=============================================
            Start Latest Project Section 
            ============================================== */}
            <section >

              <div className="container-xxl">
                {/* {/* <!--Animated Block--> */}
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >

                </div>

                {/* <!--Spacer--> */}

                {/* {/* <!--Animated Block--> */}

                {/* <!--Spacer--> */}

              </div>
              {/* End .container */}


              {/* {/* <!--Animated Block--> */}
              <div
                className="ptf-animated-block gab-portfolio"
                data-aos="fade"
                data-aos-delay="0"
              >
                <h3 className="container secondary-title centered-title fz-30 fz-90--md has-secondary-font fw-normal">Some of our recent projects</h3>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1rem", "--ptf-md": "1.125rem" }}
                ></div>
                <div
                  className="ptf-isotope-grid row"
                  style={{
                    "--bs-gutter-x": "0rem",
                    "--bs-gutter-y": "0rem",
                  }}
                >
                  <PortfolioThree />
                </div>
              </div>
              {/* End portfolio */}
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              ></div>
              <div className="text-center">
                {/* {/* <!--Animated Block--> */}

              </div>
              {/* <!--Spacer--> */}



              {/* End .container-xxl */}
            </section>
            {/* End Portfolio Endpoint */}


            {/* End section devider */}


            {/* End section devider */}

            <section>
              {/* <!--Spacer--> */}

              <div className="container text-center">
                <div className="d-inline-flex text-start">
                  {/* <!--Animated Block--> */}
                  {/* <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-30">Start a conversation with us</div>
       
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "1.25rem" }}
                    ></div>
                    <div
                      className="
                        fz-130
                        fz-90--md
                        has-black-color has-secondary-font
                        fw-normal
                        lh-1p1
                      "
                    >
                      <Link className="ptf-filled-link" to="/contact">
                        Get Started
                        <div className="fz-80 has-black-color">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            style={{ height: "1em" }}
                            viewBox="0 0 17 17"
                          >
                            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                          </svg>
                        </div>
                      </Link>

                    </div>

                  </div> */}
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "5rem", "--ptf-md": "3rem" }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-color": "var(--ptf-color-14)",
                    "--ptf-height": "1px",
                  }}
                ></div>
              </div>
            </section>
            {/* End section devider */}
          </div>
          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div>
      {/* End .ptf-site-wrapper__inner */}

      {/*=============================================
        Start Footer Section 
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div>
  );
};

export default HomeTrending;
