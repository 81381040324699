import React from "react";
import { Helmet } from "react-helmet";
import HeaderDefault from "../../components/header/HeaderDefault";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Footer from "../../components/footer/Footer";
import Social from "../../components/social/Social";
import ContactForm from "../../components/ContactForm";
import Address from "../../components/Address";
import HeaderAgency from "../../components/header/HeaderAgency";
import FooterThree from "../../components/footer/FooterThree";
import CopyRightThree from "../../components/footer/copyright/CopyRightThree";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>GMK Digital - Contact</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderAgency />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "3rem", "--ptf-md": "3rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}

                </div>
              </div>
              {/* End .row */}
            </div>
            {/* Contact top portion */}

            {/* <!--Spacer--> */}

          </section>
          {/* End section contact header */}

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-1">

                </div>
                {/* End .col */}

                <div className="col-lg-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading bolder-gab">
                        Get a Quote
                      </h1>
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                      ></div>
                      <h4 className="medium-heading">
                        We'll respond within 24 hours
                      </h4>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "5rem", "--ptf-md": "2.5rem" }}
                      ></div>


                      {/* <!--Social Icon--> */}
                    </div>
                    {/* <h5 className="fz-14 text-uppercase has-3-color fw-normal">
                      Give us a brief outline of your project and goals.
                    </h5> */}
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "0.125rem" }}
                    ></div>
                    <ContactForm />
                    {/* End ContactForm */}
                  </div>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* <!--Spacer--> */}
         
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}

      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "5.375rem" }}
      ></div>
      {/* End ptf-main */}
      <footer className="ptf-footer ptf-footer--style-2">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <FooterThree />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRightThree />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div>
  );
};

export default Contact;
