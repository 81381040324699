import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
import Social from "../../social/Social";

const menuContent = [
  {
    name: "Home",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    routerPath: "/",
    dropDownItems: [

    ],
  },

  {
    name: "The Process",
    activeClass: "",
    menuClass: "",
    routerPath: "/the-process",
    dropDownItems: [

    ],
  },
  {
    name: "Our Services",
    activeClass: "",
    menuClass: "",
    routerPath: "/our-services",
    dropDownItems: [

    ],
  },
  {
    name: "Get a Quote",
    activeClass: "",
    menuClass: "",
    routerPath: "/contact",
    dropDownItems: [

    ],
  }
];

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        <ProSidebar>
          <SidebarContent>
            <Menu className="sidebar-menu_wrapper">
              {menuContent.map((item, i) => (
                <li key={i}>
                  <Link href="#" className="mobile-menu-item" to={item.routerPath}>{item.name}</Link>
                </li>
                // <SubMenu title={item.name} key={i}>

                //   {item.dropDownItems.map((val, i) => (
                //     <MenuItem key={i}>

                //     </MenuItem>
                //   ))}
                // </SubMenu>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}

      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          @{new Date().getFullYear()} <span>GMKDigital</span>. {" "}
          <br />


          .
        </p>
        {/* <Social /> */}
      </div>
      {/* End .ptf-offcanvas-menu__footer */}
    </>
  );
};

export default MobileMenu;
