import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
const ContactForm = () => {
  const navigate = useNavigate();
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // organization: Yup.string().required("Organization name is required"),
    projectGoal: Yup.string().required("Please, write your project goal."),
    // timeline: Yup.string().required("Please write if you have timeline."),
    email: Yup.string()
      .required("Email or Phone number is required")
      // .email("Entered value does not match email format"),
    // budget: Yup.string().required("Pleaes select your budget"),
    // acceptTerms: Yup.bool().oneOf(
    //   [true],
    //   "Please check this field to proceed"
    // ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    // display form data on success
    console.log("Message submited: " + JSON.stringify(data));


    fetch('https://formsubmit.co/ajax/gabriel.m.kennedy@gmail.com', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(data)
    })
    navigate("/thanks");
    e.target.reset();

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contact_form">

      {/* End .ptf-form-group */}
      {/* 
      <div className="ptf-form-group">
        <label data-number="02">What’s the name of your organization?</label>
        <input
          type="text"
          name="organization"
          {...register("organization")}
          className={`${errors.organization ? "is-invalid" : ""}`}
        />
        {errors.organization && (
          <div className="invalid-feedback">{errors.organization?.message}</div>
        )}
      </div> */}
      {/* End .ptf-form-group */}

      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="01">What’s your name?</label>
        <input
          type="text"
          name="name"
          {...register("name")}
          className={`${errors.name ? "is-invalid" : ""}`}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name?.message}</div>
        )}
      </div>
      <div className="ptf-form-group">
        <label data-number="04"> Provide a brief outline of your project and goals.</label>
        <textarea
          type="text"
          name="projectGoal"
          {...register("projectGoal")}
          className={`${errors.projectGoal ? "is-invalid" : ""}`}
        />
        {errors.projectGoal && (
          <div className="invalid-feedback">{errors.projectGoal?.message}</div>
        )}
      </div>

      <div className="ptf-form-group">
        <label data-number="05">Do you have a timeline in mind?</label>
        <input
          type="text"
          name="timeline"
          {...register("timeline")}
          className={`${errors.timeline ? "is-invalid" : ""}`}
        />
        {errors.timeline && (
          <div className="invalid-feedback">{errors.timeline?.message}</div>
        )}
      </div>

      <div className="ptf-form-group">
        <label data-number="06">What have you budgeted for this project?</label>
        <select
          name="budget"
          {...register("budget")}
          className={`${errors.budget ? "is-invalid" : ""}`}
        >
          <option value="5k+">Over $5000 NZD</option>

          <option value="10k+">Over $10,000 NZD</option>
          <option value="20k+">Over $20,000 NZD</option>
          <option value="5k+">$5000 NZD or less</option>



        </select>
        {errors.budget && (
          <div className="invalid-feedback">{errors.budget?.message}</div>
        )}
      </div>

      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        <label data-number="03">Please provide an email address or contact phone number to reach you on.</label>
        <input
          name="email"
          type="text"
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>



      {/* End .ptf-form-group */}


      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      {/* <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div> */}
{/* 
      <div className="ptf-form-group agreement-checkbox ">
        <input
          name="acceptTerms"
          type="checkbox"
          id="acceptTerms"
          {...register("acceptTerms")}
          className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
        />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span>I'm not a robot
        </label>
        {errors.acceptTerms && (
          <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
        )}
      </div> */}
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }}></div>

      <button className="ptf-submit-button">
        <span className="ptf-filled-link">
          Submit
        </span>

      </button>
      {/* End .ptf-submit-button */}
    </form>
  );
};

export default ContactForm;
