import React from "react";
import { Link } from "react-router-dom";

const portfolioContent = [
  {
    img: "boplly5",
    categorie: "Bespoke Website & Ecommerce",
    title: "Boplly Apparel",
    url: 'web.archive.org/web/20211225091611/https://boplly.com/'
  },
  {
    img: "delisio",
    categorie: "Mobile application and website + API",
    title: "Delisio Food Discovery App",
  },
  {
    img: "kaaio12",
    categorie: "Bespoke Website",
    title: "Kaaio Meditation",
    url: 'kaaio.net'
  },
  {
    img: "hiss4",
    categorie: "Full package web app",
    title: "Hiss.gg - Browser Gaming Platform",
    url: 'hiss.gg'
  },
  {
    img: "cheri13",
    categorie: "Full package web app",
    title: "Cheri Programming School",

  },
  {
    img: "multibox5",
    categorie: "Ecommerce Website",
    title: "OSRSMultibox",
    url: 'osrsmultibox.com'
  },
];

const PortfolioThree = () => {
  return (
    <>
      {portfolioContent.map((val, i) => (
        <div className="col-lg-6 porto" key={i}>
          <div className=" grid-item filter-1 filter-4 filter-7">
            {/* <!--Portfolio Item--> */}
            <article className="ptf-work ptf-work--style-6">
              <div className="ptf-work__media">
                <img
                  className={val.img}
                  src={`assets/img/home/studio/${val.img}.png`}
                  alt={val.title}
                  loading="lazy"
                />
              </div>
              <Link className="ptf-work__meta" target="_blank" to={`https://${val.url}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  style={{ height: "1em" }}
                  viewBox="0 0 17 17"
                >
                  <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
                <div className="ptf-work__category">{val.categorie}</div>
                <h4 className="ptf-work__title">{val.title}</h4>
              </Link>
            </article>
          </div>
        </div>
      ))}
    </>
  );
};

export default PortfolioThree;
