import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    routerPath: "/",
    dropDownItems: [

    ],
  },







  {
    name: "The Process",
    activeClass: "",
    menuClass: "",
    routerPath: "/the-process",
    dropDownItems: [

    ],
  },
  {
    name: "Our Services",
    activeClass: "",
    menuClass: "",
    routerPath: "/our-services",
    dropDownItems: [

    ],
  },
  {
    name: "Get a Quote",
    activeClass: "",
    menuClass: "",
    routerPath: "/contact",
    dropDownItems: [

    ],
  },

];

const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          {item.scrollPath ? (
            <a href={`${item.scrollPath}`}>  <span>{item.name}</span></a>
          ) :
            <Link to={item.routerPath} href="#" className={item.activeClass}>
              <span>{item.name}</span>
            </Link>
          }

        </li>
      ))
      }
    </ul >
  );
};

export default DropdownMenu;
